import { Link } from "gatsby"
import React, { Component } from "react"
import Layout from "../components/layout"
import {graphql } from "gatsby"

import {
	CarouselProvider,
	Slide,
	Slider,
	ButtonBack,
	ButtonNext
} from "pure-react-carousel"
import 'pure-react-carousel/dist/react-carousel.es.css'


import HeadingBanner from "../components/headingbanner"
import VideoHolder from "../components/videoholder"
import VideoPlayer from "../components/videoplayer"
import VideoThumbnail from "../components/videothumbnail"
import ContentDivider from "../components/contentdivider"

import LocalImage from "../components/localimage"

import * as styles_slideshow from '../components/slideshow/style.module.css'
import * as styles from "../styles/pages/index.module.css"

import homestatistics from "../content/homestatistics.json"
import homerecentlyadded from "../content/homerecentlyadded.json"

import homecategories from "../content/categorytitles.json"


import arrowleft from "../images/index/arrow-left.png"
import arrowright from "../images/index/arrow-right.png"

import arrowdown from "../images/index/arrow-down.png"

import thumbcorner from "../images/videothumbnail/cornerdark.png"

import btnplaylist from "../images/index/playlist.png"

var explorelist = [
	{"id":"beats"},
	{"id":"sanity"},
	{"id":"diy"},
	{"id":"now"},
	{"id":"lolrolls"},
	{"id":"be_better"},
	{"id":"celso"},
	{"id":"bloom"},
	{"id":"loveandbeyond"},
	{"id":"classics"}
];

export default class MainIndex extends Component {


	state = {
		displayType: 0,
		videoid: "",
		videotype: "",
		videotitle: "",
		videodescription: "",
		videothumbnail: ""
	};

	componentDidMount() {
		this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
	}

	handleThumbnailClick = (videodata) => {
		this.setState({
			videotype: videodata.videotype,
			videoid: videodata.videoid,
			videotitle: videodata.title,
			videodescription: videodata.writeup,
			videothumbnail: videodata.thumbnail
		});
	}
	handleVideoClose = () => {
		this.setState({
			videotype: "",
			videoid: "",
			videotitle: "",
			videodescription: ""
		});
	}


	handleJump = () => {
		document.getElementById('explore').scrollIntoView({
			behavior: 'smooth'
		});
	}

	handleResize = (newtype) => {
		console.log(newtype);
		this.setState(state => ({
			displayType: 	newtype
		}));
	};
	render() {
		const structuredData = {
			"@context": "https://schema.org",
			"@type": "WebSite",
			"url": this.props.data.site.siteMetadata.siteUrl,
			"description": this.props.data.site.siteMetadata.description,
			"mainEntityOfPage": this.props.data.site.siteMetadata.title,
			"name": this.props.data.site.siteMetadata.title,
			"image": this.props.data.site.siteMetadata.siteUrl+"/images/fb.jpg",
			"logo": this.props.data.site.siteMetadata.siteUrl+"/images/logo-square.png",
			"@id": this.props.data.site.siteMetadata.siteUrl
		}
		return (
			<Layout activePath='' pageTitle="Home" resizeHandler={this.handleResize} structuredData={structuredData}>
				<HeadingBanner className={styles.headingholder+" "+styles.homecontent+" color-background-secondary"}>
					<h1 className={styles.heading+" font-size-large fullblock-mobile-only"}>
						<div className={"font-size-huge"}>
							Watch
							<span className={"color-text-highlight-green"}>Free</span>
						</div>
						<div>BOYSEN originals</div>
					</h1>
					<VideoHolder videotitle={"Pick your BOYSEN story"} description={this.props.data.site.siteMetadata.description} roundedCorner={true} className={styles.headingvideo} videoid={"2b9mfGiPBfk"} mode={"embed"} />
					<div className={styles.headingtext}>
						<h2 className={styles.heading+" font-size-large fullblock-desktop-only"}>
							<div>Watch</div>
							<div className={"color-text-highlight-green font-size-huge"}>Free</div>
							<div>BOYSEN</div>
							<div>originals</div>
						</h2>
						<ContentDivider/>
						<div className={"color-text-grayed"}>
							Studio B has the right kind of mix to match the content you've been looking for. We've got original music, talk, shorts, and stand-up comedy shows. You can also get crafty with cool DIY inspirations and smart painting tips. Catch up on our funny and heart-tugging original videos and take a trip back in time to watch classic Boysen ads. Happy viewing!
						</div>
					</div>
					<div className={styles.headingstatistics}>
						{homestatistics.map((entry) =>
							<div className={"font-size-regular "+styles.headingstatisticsitem}>
								<span className={"font-size-large"}>{entry.value}</span>
								{entry.title.map((titleline)=><div className="font-style-light">{titleline}</div>)}
							</div>
						)}
					</div>
					<ContentDivider className="fullblock-mobile-only"/>
					<h2 className={styles.recenttitle}>Recently Added</h2>
					<CarouselProvider
						naturalSlideWidth={1}
						naturalSlideHeight={this.state.displayType<=1? 1: 1.5}
						totalSlides={homerecentlyadded.length}
						isPlaying={false}
						hasMasterSpinner={false}
						className={styles_slideshow.container}
						infinite={true}
						visibleSlides={this.state.displayType<=1? 3: 2}
					>
						<Slider className={styles.thumbnailslider}>
						{
							homerecentlyadded.map((videodata, videoindex)=> {
								return (
									<Slide index={videoindex} className={styles.thumbnail+" "+styles["thumbnail"+videoindex]}>
										<VideoThumbnail hasTeaser={true} className={styles.recentvideothumbnail} videodata={videodata} onclickhandler={this.handleThumbnailClick} />
									</Slide>
								)
							})
						}
						</Slider>
						<ButtonBack
							className={`${styles_slideshow.buttonBack} ${styles_slideshow.button} ${styles.recentarrowback}`}
							onClick={this.disableAnimation}
						>
							<img src={arrowleft} alt='<'/>
						</ButtonBack>
						<ButtonNext
							className={`${styles_slideshow.buttonNext} ${styles_slideshow.button} ${styles.recentarrownext}`}
							onClick={this.disableAnimation}
						>
							<img src={arrowright} alt='>'/>
						</ButtonNext>
					</CarouselProvider>

					<div className={styles.headingbannermore}>
						<div role="button" onClick={this.handleJump}>
							<img src={arrowdown} alt={"More"} />
						</div>
					</div>

				</HeadingBanner>
				<div className={styles.homecontent+" "+styles.explorecontent} id="explore">
					<h2 className={"color-border-bottom-white font-size-large "+styles.exploreheading}>
						Explore
					</h2>
					{
						explorelist.map((exploreitem, exploreidx)=>{
							return (
								<Link to={"/"+exploreitem.id} className={styles.exploreitemrow+" text-centered color-text-secondary"}>
									{((exploreidx&1)===0 || this.state.displayType > 1 ) &&
										<>
											<div className={styles.explorethumnailholder}>
												<LocalImage className={styles.explorethumnailimage} src={"index/thumb/"+exploreitem.id+".jpg"} alt={homecategories[exploreitem.id].title} />
												<img className={styles.explorethumnailcorner} src={thumbcorner} alt="" />
											</div>
											<div className={styles.explorethumnailgap}>&nbsp;</div>
										</>
									}
									<div className={styles.exploredescriptionholder}>
										<LocalImage placeholder="none" className={styles.exploredescriptiontitle} src={"index/title/"+exploreitem.id+".png"} alt={homecategories[exploreitem.id].title} />
										<div className={styles.exploredescriptioncontent+" font-size-small"} >
											{homecategories[exploreitem.id].description}
										</div>
										<div className={styles.exploredescriptionplaylist}>
											<img src={btnplaylist} alt="View Playlist" />
										</div>
									</div>
									{((exploreidx&1)===1 && this.state.displayType <= 1  ) &&
										<>
											<div className={styles.explorethumnailgap}>&nbsp;</div>
											<div className={styles.explorethumnailholder}>
												<LocalImage className={styles.explorethumnailimage} src={"index/thumb/"+exploreitem.id+".jpg"} alt={homecategories[exploreitem.id].title} />
												<img className={styles.explorethumnailcorner} src={thumbcorner} alt="" />
											</div>
										</>
									}
								</Link>
							)

						})
					}
				</div>
				<VideoPlayer
					className={""}
					videotype={this.state.videotype}
					videoid={this.state.videoid}
					videotitle={this.state.videotitle}
					videodescription={this.state.videodescription}
					videothumbnail={this.state.videothumbnail}
					onclosehandler={this.handleVideoClose} />
			</Layout>
		)
	}

}


export const data = graphql`
	query {
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		}
	}
`
